import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from "../components/Layout";
import {RichText} from "prismic-reactjs";
import {Helmet} from "react-helmet";

export const Page = ({ location, path, data }) => {
    if (!data) return null
    const page = data.allPrismicPage.edges[0].node.data;

    const pageTitle = RichText.asText(page.page_title.raw);
    const pageContent = RichText.render(page.page_content.raw);

    const accentColor = "#006D78";

    return (
        <Layout location={location} path={path}>
            <Helmet style={[{
                "cssText": `
                    :root {
                    --accent-color: ${accentColor};
                    }
                `
            }]} />
            <div className="cms-page">
                <header className="cms-page-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>{pageTitle}</h1>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="cm-page__content">
                                {pageContent}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export const query = graphql`
    query CMSPageQuery($uid: String) {
        allPrismicPage(filter: { uid: { eq: $uid } }) {
            edges {
                node {
                    url
                    id
                    type
                    first_publication_date
                    last_publication_date
                    data {
                        page_title {
                            raw
                        }
                        page_content {
                            raw
                        }
                    }
                }
            }
        }
    }
`

export default Page;
